<template>
  <div class="work-experience">
    <el-alert
      type="warning"
      :closable="false"
      description="证明资料仅限PDF文档(资料包括劳动合同、社保缴纳证明、工牌、离职证明等，请把资料内容放到PDF文档上传)"
      show-icon>
    </el-alert>
    <div class="form sctp-bg-white pad15">
      <el-form :model="form" status-icon :rules="rules" ref="rules"
               class="demo-ruleForm" size="mini">
        <el-row v-for="(experiences, index) in form.experiences" :key="index" type="flex">
          <el-form-item>
            {{ index + 1 }}、
          </el-form-item>
          <el-form-item :prop="`experiences.${index}.starttime`" :rules="rules.starttime">
            <el-date-picker type="date" v-model="experiences.starttime"
                            placeholder="工作开始时间" value-format="yyyy-MM-dd"></el-date-picker>
          </el-form-item>
          ~
          <el-form-item :prop="`experiences.${index}.endtime`" :rules="rules.endtime">
            <el-date-picker type="date" v-model="experiences.endtime"
                            placeholder="工作结束时间" value-format="yyyy-MM-dd">

            </el-date-picker>
          </el-form-item>
          <el-form-item :prop="`experiences.${index}.companyname`" :rules="rules.companyname">
            <el-input v-model="experiences.companyname" placeholder="公司名称"></el-input>
          </el-form-item>
          <el-form-item :prop="`experiences.${index}.president`" :rules="rules.president">
            <el-input v-model="experiences.president" placeholder="岗位"></el-input>
          </el-form-item>
          <el-form-item prop="remarks">
            <el-input v-model="experiences.remarks" placeholder="备注"></el-input>
          </el-form-item>
          <el-form-item>
            <el-upload
              :http-request="fileUploadFunction"
              :limit="2"
              :data="{...uploadConfig.uploadEnum.JOB}"
              :on-exceed="uploadConfig.fileExceed"
              action=""
              class="file-inline"
              :file-list="experiences.provesList || []"
              :show-file-list="true"
              :before-upload="uploadConfig.beforeUpload.bind(null, {item: experiences})"
              accept=".PDF"
              :on-success="uploadConfig.uploadSuccess.bind(null,{item: experiences})"
              :on-remove="uploadConfig.onRemove.bind(null,{item: experiences})">
              <el-button size="mini" icon="el-icon-link">上传证明材料</el-button>
              <div class="el-upload__tip" slot="tip"></div>
            </el-upload>
          </el-form-item>
          <el-form-item>
            <el-button size="mini" class="reduce-item" circle
                       @click="deleteLine(index)"></el-button>
          </el-form-item>
        </el-row>
        <el-row class="sctp-flex pad15">
          <el-form-item>
            <el-button type="primary" @click="submitForm()">保存</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary"
                       @click="form.experiences.push({ userid: null, awardname: null, imgurl: null })">
              <i
                class="el-icon-plus"></i>增加
            </el-button>
          </el-form-item>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
import uploadEnum from "@/resources/js/uploadenum";

export default {
  name: 'workExperience',
  data() {
    return {
      form: {
        experiences: [{
          userid: null,
          starttime: null,
          endtime: null,
          companyname: null,
          president: null,
          remarks: null,
          id: null,
          proves: null,
          provesList: null,
        }]
      },
      rules: {
        starttime: [
          {
            validator: (rule, value, callback) => {
              var index = rule.field.indexOf('.')
              let ind = parseInt(rule.field.substr(index+1,1))
              let va1 = new Date(this.form.experiences[ind].endtime)
              let va2 = new Date(value)

              //时间清楚后默认为1970-01-01
              if (va1 < va2 && va1.getTime() != new Date('1970-01-01').getTime()) {
                this.form.experiences[ind].starttime = ""
                this.$message.error('工作开始时间不能大于工作结束时间')
              } else {
                callback();
              }
            },
            trigger: ['change', 'blur']
          },{required: true, message: '请输入工作开始时间'}
        ],
        endtime: [{required: true, message: '请输入工作结束时间'},
          {
            validator: (rule, value, callback) => {
              var index = rule.field.indexOf('.')
              let ind = parseInt(rule.field.substr(index+1,1))
              let va1 = new Date(this.form.experiences[ind].starttime)
              let va2 = new Date(value)

              if (va1 > va2) {
                this.form.experiences[ind].endtime = ""
                this.$message.error('工作结束时间不能小于工作开始时间')
              } else {
                callback();
              }
            },
            trigger: ['change', 'blur']
          }],
        companyname: [{required: true, message: '请输入企业名称'}],
        president: [{required: true, message: '请输入工作职位'}]
      },
      uploadConfig: {
        uploadEnum,
        uploadSuccess: ({item}, response, file, fileList) => {
          this.$set(item, 'provesList', fileList);
          this.$message.success('文件上传成功！');
        },
        onRemove: ({item}, file, fileList) => {
          this.$set(item, 'provesList', fileList);
        },
        beforeUpload: ({item}, file) => {
          let result = false;
          const isMatchType = 'application/pdf' === file.type;
          const isLtSize = file.size / 1024 / 1024 < 1;
          if (!isMatchType) {
            this.$message.error('只支持PDF文件!');
            return false;
          }
          if (!isLtSize) {
            this.$message.error(`上传文件大小不能超过 1MB!`);
            return false;
          }
          result = isMatchType && isLtSize;
          return result && this.$message('文件正在上传') && true;
        },
        fileExceed() {
          this.$message.warning(`文件数量已达上限！`);
        },
        uploadFileError() {
          this.$message.error('文件上传失败')
        },
      }
    }
  },
  methods: {
    submitForm() {
      this.$refs.rules.validate().then(() => {
        let userId = this.user.userId;
        let {experiences} = this.form;
        let data = experiences.map(item => {
          let temp = {};
          temp.userid = userId;
          temp.starttime = item.starttime;
          temp.endtime = item.endtime;
          temp.companyname = item.companyname;
          temp.president = item.president;
          temp.remarks = item.remarks;
          temp.id = item.id;
          temp.proves = item.provesList && item.provesList.map(
            item => item.path || item.response.src).join(',') || null;
          return temp;
        });
        this.$request.post({
          reqcode: '1119',
          reqdesc: '覆盖更新工作经历',
          data: data
        }).then(res => {
          this.$message.success('保存成功')
          this.queryList()
        })
      }).catch((err) => {
        console.log(err);
        this.$message.warning('表单填写错误')
      });
    },
    queryList() {
      this.$request.post({
        reqcode: '1118',
        reqdesc: '查询工作经历',
        userid: this.user.userId,
        invalid_cache: new Date().getTime(),
      }).then(res => {
        let {data} = res;
        data.forEach(work => {
          work.starttime = this.dateToString(new Date(work.starttime), 'yyyy-MM-dd')
          work.endtime = this.dateToString(new Date(work.endtime), 'yyyy-MM-dd')
          work.provesList = work.proves && work.proves.split(',').map(item => {
            return {
              url: item,
              path: item.substring(item.indexOf('/upload/')),
              name: item.substring(item.lastIndexOf('/') + 1)
            }
          }) || [];
        })
        if (data && data.length > 0) {
          this.form.experiences = data;
        }
      })
    },
    deleteLine(index) {
      const id = this.form.experiences[index].id
      if (id != null) {
        this.$confirm('确定要删除嘛?', '提示', {})
        .then(() => {
          this.$request.post({
            reqcode: '1123',
            reqdesc: '删除个人履历信息',
            id: id,
            type: 1
          }).then(res => {
            this.$message.success(res.retDesc)
            this.form.experiences.splice(index, 1)
          })
        })
      } else {
        this.form.experiences.splice(index, 1)
      }
    }
  },
  mounted() {
    this.queryList()
  }
}
</script>

<style scoped lang="scss">
.work-experience {
  /deep/ {
    .file-inline {
      display: flex;
      align-items: center;

      .el-upload-list {
        display: flex;
      }

      .el-upload-list__item {
        margin-top: 0;
        display: flex;
        flex-direction: column;
        margin-left: 5px;

        .el-upload-list__item-name {
          text-overflow: clip;
          width: 20px;
          margin-right: 0;
          padding-left: 0;
          line-height: 1;
        }

        .el-icon-close {
          display: block;
          position: relative;
          top: 0;
          right: 0;
          opacity: 1;
        }

        .el-upload-list__item-status-label {
          display: none;
        }

      }
    }
  }
}

.el-form-item {
  margin: 0 5px;
}

.add-item, .reduce-item {
  background: url(/images/user/experience/add-reduce.jpg);
  background-repeat: no-repeat;
  background-size: 63px;
  border: 0;
  padding: 0;
  width: 30px;
  height: 38px;
}

.reduce-item {
  background-position: -31px 0;
}

.el-date-editor.el-input, .el-date-editor.el-input__inner {
  width: 150px;
}
</style>
